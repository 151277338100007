@font-face {
	font-family: 'OfficeCodePro-Light';
	src: url('fonts/OfficeCodePro-Light.otf'); /* IE9 Compat Modes */
}

@font-face {
	font-family: 'spectral-extralight';
	src: url('fonts/spectral-extralight.ttf'); /* IE9 Compat Modes */
}

@font-face {
	font-family: 'EditorialNew-Ultralight';
	src: url('fonts/EditorialNew-Ultralight.otf'); /* IE9 Compat Modes */
}

@font-face {
	font-family: 'PolySans-Slim';
	src: url('fonts/PolySans-Slim.otf'); /* IE9 Compat Modes */
}



@font-face {
	font-family: 'ArchivoBlack-Regular';
	src: url('fonts/ArchivoBlack-Regular.otf'); /* IE9 Compat Modes */
}


@font-face {
	font-family: 'gapsans-webfont';
	src: url('fonts/gapsans-webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
}

*{
	text-decoration: none;
}

body{
  margin: 0;
  overflow-x: hidden;
}

.image_component_outer{
  position: absolute;
  margin: 2vw;
  transform: rotate(3deg);
  filter: blur(30px);
  transition: 2s;
  cursor: pointer;
}

.image_component_outer:hover{
	filter: blur(0px);
	transition: 0s;
}


.image_component_outer span{
  display: block;
  font-family: OfficeCodePro-Light;
  font-size: 0.8vw;
  margin-top: 0.4vw;
  text-align: center;
}

.image_filtered_main_outer{
  position: absolute;
	top: 0;
}

.image_filtered_main_outer div img,
.lightbox_headlines{
  width: 96%;
	padding: 2%;
}

.header_component_main_outer h1{
	color: white;
	margin: 0;
	font-size: 1vw;
	font-family: 'OfficeCodePro-Light';
	font-weight: 100;
	text-transform: uppercase;
}

.lightbox_description{
  margin-top: 1vw;
	margin-bottom: 1vw;
}

.lightbox_description h1{
	font-family: 'OfficeCodePro-Light';
  font-weight: 100;
  color: white;
  margin: 0;
  padding-top: 2vw;
  padding-bottom: 2vw;
  font-size: 20px;
  padding: 2%;
  width: 75%;
}

.lightbox_iframe_container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.lightbox_iframe_container iframe{
	display: block;
  background: #000;
  border: none;
  height: calc(91vh - 80px);
  width: 80vw;
  margin-top: 2vw;
  transform: scale(0.97);
}

.lightbox_headlines h1{
  font-family: EditorialNew-Ultralight;
	font-weight: 100;
	color: white;
	margin: 0;
	padding-top: 2vw;
	padding-bottom: 2vw;
	font-size: 6vw;
	border-bottom: 2px solid white;
}

.lightbox_container{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100vw;
	position: fixed;
	z-index: 10000000;
}

.lightbox{
	position: fixed;
  height: 80vh;
  width: 80vw;
  background-color: black;
  z-index: 10;
  overflow: scroll;
  border: 2px solid white;
}


svg{
  position: absolute;
}

text{
  text-transform: uppercase;
  font-family: OfficeCodePro-Light;
  font-size: 3vw;
}

textPath{
  color: white;
}

.shuffle_button{
	position: fixed;
	top: 10vh;
	left: 0;
	margin: 2vw;
	width: fit-content;
	background-color: white;
	cursor: pointer;
}

.shuffle_button:hover{
	animation-name: shake-little;
	animation-duration: 2s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
}

.shuffle_button img{
	padding: 1vw;
	width: 60px;
	padding-top: 1.5vw;
	padding-bottom: 1.5vw;
}

.text_component_main_outer{
	position: fixed;
	margin: 0;
	left: 0;
	bottom: 0;
	z-index: 100;
	mix-blend-mode: difference;
}

.about_container{
	padding: 2vw;
	padding-top: 6vw;
	width: 96%;
  z-index: 100;
	position: fixed;
}

.about_container h1{
	color: white;
	font-size: 2.7vw;
	font-weight: 100;
	font-family: 'EditorialNew-Ultralight';
	margin-top: 0;
	line-height: 1.4;
}

.about_links_outer{
	display: flex;
	align-items: baseline;
	width: 100%;
	border-top: 2px solid white;
	padding-top: 5vw;
}

.about_links{
	width: 25%;
}

.about_links a{
	color: white;
	display: block;
	font-size: 2.5vw;
	font-family: "PolySans-Slim";
	text-decoration: none;
}

.about_cta{
	position: fixed;
	bottom: 1vw;
	display: flex;
	align-items: baseline;
	width: 99%;
}

.about_cta *{
  width: 24.5%;
}


.about_container h2{
	text-transform: uppercase;
	color: white;
  font-size: 3vw;
  font-weight: 100;
  font-family: "PolySans-Slim";
	cursor: pointer;
	margin: 0;
}

.about_container_cta{
	height: 100vh;
	width: 100vw;
	display: block;
	position: fixed;
	background-color: transparent;
	top: 0;
	left: 0;
	z-index: -1;
}

svg{
  position: absolute;
  top: 0;
  left: 0;
}

text {
  fill: white;
  font-family: OfficeCodePro-Light;
  letter-spacing: 2px;
  font-size: 5vw;
}


.main_info_text{
  display: inline;
  margin: 2vw;
  transform: scale(0.8);
	padding-right: 5vw;
}


header{
	position: fixed;
	top: 0;
	left: 0;
	padding: 2vw;
	padding-top: 1vw;
	width: 96vw;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100000;
}

header div{
  width: 50%;
}

.project_page{
	padding: 2vw;
	padding-top: 4vw;

	color: #f43a30;
text-transform: uppercase;
font-size: 8vw;
font-family: ArchivoBlack-Regular;
font-weight: 100;
margin: 0;
line-height: 0.9;
margin: 0.4vw;
white-space: nowrap

}


header .last{
  display: flex;
  align-items: baseline;
}

header .last div{
  width: 50%;
}


.main_info_text span{
	color: white;
	text-transform: capitalize;
	font-size: 18vw;
	font-family: PolySans-Slim;
	font-weight: 100;
	margin: 0;
	line-height: 0.9;
	margin: 0.4vw;
	white-space: nowrap;
	cursor: pointer;
}



	@media screen and (max-width: 600px) {

		body{
			width: 100%;
			overflow-x: hidden;
		}


		.lightbox_headlines h1{
			font-size: 10vw;
		}

		.shuffle_button{
			position: fixed;
			top: 0;
			right: 0;
			left: auto;
		}

		.shuffle_button img{
			padding: 3vw;
			width: 75px;
		}

		.lightbox{
			height: 70vh;
			width: 85vw;
			margin-top: -5vh;
		}

		.lightbox_description h1{
			font-size: 15px;
		}

		.about_container{
			top: 16vw;
		}

		.about_container h2{
			font-size: 9vw;
		}

		.main_info_text span{
			font-size: 30vw;
		}

		.text_component_main_outer{
			bottom: 3vw;
			mix-blend-mode: normal;
		}

	 .image_component_outer{
		 position: inherit;
		 animation: blurMobileImg 5s infinite;
		 margin-bottom: 100px;
	 }

	 .header_component_main_outer h1{
		 font-size: 4vw;
	 }


	 header{
		 flex-direction: column;
	 }

	 header div{
		 width: 100%;
	 }

	 #image_main{
		 margin-top: 50vw;
	 }

	 .about_container h1{
		font-size: 5vw;
		margin: 0;
	 }

	 .about_links_outer{
		 flex-direction: column;
	 }

	 .about_links a{
		 font-size: 5vw;
	 }

	 .shuffle_button h1{
		 font-size: 5vw;
	 }

	 .image_component_outer img{
		 width: 100% !important;
	 }

	 .image_component_outer span{
		 font-size: 5vw;
	 }

	}



	/* anim declaration here */


	.skewAnim{
		animation: skewAnim 4s infinite;
	}

	.rotationAnim{
		animation: rotationAnim 4s infinite;
	}

	.rotationPathAnim{
		animation: rotationPathAnim 4s infinite;
	}

	.translateAnim{
		animation: translateAnim 4s infinite;
	}


	.polygonAnim{
		animation: polygonAnim 6s infinite;
	}

	/* keyframes here */


	@keyframes blurMobileImg {
		0%{
			filter: blur(0px);
		}
		50%{
			filter: blur(30px);
		}
		75%{
			filter: blur(0px);
		}
		100%{
			filter: blur(0px);
		}
	}


	 @keyframes skewAnim {
	 	0%,
	 	11.1%,
	 	to {
	 		transform: none
	 	}
	 	22.2% {
	 		transform: skewX(-12.5deg) skewY(-12.5deg)
	 	}
	 	33.3% {
	 		transform: skewX(6.25deg) skewY(6.25deg)
	 	}
	 	44.4% {
	 		transform: skewX(-3.125deg) skewY(-3.125deg)
	 	}
	 	55.5% {
	 		transform: skewX(1.5625deg) skewY(1.5625deg)
	 	}
	 	66.6% {
	 		transform: skewX(-.78125deg) skewY(-.78125deg)
	 	}
	 	77.7% {
	 		transform: skewX(.390625deg) skewY(.390625deg)
	 	}
	 	88.8% {
	 		transform: skewX(-.1953125deg) skewY(-.1953125deg)
	 	}
	 }


	 @keyframes rotationAnim {
	  from {
	    transform: rotate(0deg);
	  }
	  to {
	    transform: rotate(359deg);
	  }
	}


	@keyframes rotationPathAnim {
		0% {
			transform: rotate(0deg) translate(-150px) rotate(0deg)
		}
		to {
			transform: rotate(1turn) translate(-150px) rotate(-1turn)
		}
	}


	@keyframes translateAnim{
		0% {
			transform: translate(0)
		}
		45% {
			transform: translate(10px, -11px)
		}
		83% {
			transform: translate(50px, 100px)
		}
		to {
			transform: translate(0)
		}
	}


	@keyframes polygonAnim {
	  0% { clip-path: polygon(0 0, 50% 0, 100% 0, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%); }
	   50% { clip-path:  polygon(50% 50%, 50% 25%, 50% 50%, 75% 50%, 50% 50%, 50% 75%, 50% 50%, 25% 50%); }
	   100% { clip-path: polygon(0 0, 50% 0, 100% 0, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%); }
	 }



	 /*! * * * * * * * * * * * * * * * * * * * *\
	   CSShake :: shake-slow
	   v1.5.0
	   CSS classes to move your DOM
	   (c) 2015 @elrumordelaluz
	   http://elrumordelaluz.github.io/csshake/
	   Licensed under MIT
	 \* * * * * * * * * * * * * * * * * * * * */
	 .shake-slow {
	   display: inline-block;
	   transform-origin: center center; }

	 .shake-freeze,
	 .shake-constant.shake-constant--hover,
	 .shake-trigger .shake-constant.shake-constant--hover {
	   animation-play-state: paused; }

	 .shake-freeze,
	 .shake-trigger .shake-freeze, .shake-slow,
	 .shake-trigger .shake-slow {
	   animation-play-state: running; }

	 @keyframes shake-slow {
	   2% {
	     transform: translate(0px, -3px) rotate(3.5deg); }
	   4% {
	     transform: translate(-9px, -3px) rotate(0.5deg); }
	   6% {
	     transform: translate(-7px, 4px) rotate(-0.5deg); }
	   8% {
	     transform: translate(7px, 5px) rotate(2.5deg); }
	   10% {
	     transform: translate(-1px, -3px) rotate(-1.5deg); }
	   12% {
	     transform: translate(-3px, 8px) rotate(1.5deg); }
	   14% {
	     transform: translate(9px, -7px) rotate(2.5deg); }
	   16% {
	     transform: translate(1px, -9px) rotate(3.5deg); }
	   18% {
	     transform: translate(-4px, 7px) rotate(-1.5deg); }
	   20% {
	     transform: translate(6px, 3px) rotate(0.5deg); }
	   22% {
	     transform: translate(4px, -2px) rotate(-1.5deg); }
	   24% {
	     transform: translate(-7px, -8px) rotate(3.5deg); }
	   26% {
	     transform: translate(-5px, 9px) rotate(2.5deg); }
	   28% {
	     transform: translate(0px, -2px) rotate(-1.5deg); }
	   30% {
	     transform: translate(5px, 9px) rotate(1.5deg); }
	   32% {
	     transform: translate(6px, 2px) rotate(-1.5deg); }
	   34% {
	     transform: translate(-9px, 9px) rotate(2.5deg); }
	   36% {
	     transform: translate(-8px, 10px) rotate(3.5deg); }
	   38% {
	     transform: translate(2px, -4px) rotate(-2.5deg); }
	   40% {
	     transform: translate(2px, 7px) rotate(-2.5deg); }
	   42% {
	     transform: translate(2px, 4px) rotate(3.5deg); }
	   44% {
	     transform: translate(-8px, -3px) rotate(2.5deg); }
	   46% {
	     transform: translate(5px, -3px) rotate(1.5deg); }
	   48% {
	     transform: translate(9px, 4px) rotate(3.5deg); }
	   50% {
	     transform: translate(-1px, 2px) rotate(-1.5deg); }
	   52% {
	     transform: translate(-9px, 4px) rotate(-1.5deg); }
	   54% {
	     transform: translate(-1px, -6px) rotate(-2.5deg); }
	   56% {
	     transform: translate(4px, -6px) rotate(0.5deg); }
	   58% {
	     transform: translate(-8px, 2px) rotate(2.5deg); }
	   60% {
	     transform: translate(2px, 10px) rotate(3.5deg); }
	   62% {
	     transform: translate(5px, 5px) rotate(1.5deg); }
	   64% {
	     transform: translate(5px, 7px) rotate(-0.5deg); }
	   66% {
	     transform: translate(-6px, 9px) rotate(-0.5deg); }
	   68% {
	     transform: translate(4px, -2px) rotate(0.5deg); }
	   70% {
	     transform: translate(-5px, -3px) rotate(3.5deg); }
	   72% {
	     transform: translate(2px, 5px) rotate(-0.5deg); }
	   74% {
	     transform: translate(-9px, 4px) rotate(3.5deg); }
	   76% {
	     transform: translate(3px, -7px) rotate(1.5deg); }
	   78% {
	     transform: translate(0px, 4px) rotate(2.5deg); }
	   80% {
	     transform: translate(-1px, 4px) rotate(1.5deg); }
	   82% {
	     transform: translate(8px, 2px) rotate(1.5deg); }
	   84% {
	     transform: translate(7px, -6px) rotate(-0.5deg); }
	   86% {
	     transform: translate(3px, 0px) rotate(0.5deg); }
	   88% {
	     transform: translate(7px, -9px) rotate(-1.5deg); }
	   90% {
	     transform: translate(1px, 3px) rotate(0.5deg); }
	   92% {
	     transform: translate(10px, 3px) rotate(-0.5deg); }
	   94% {
	     transform: translate(4px, 1px) rotate(1.5deg); }
	   96% {
	     transform: translate(3px, 9px) rotate(2.5deg); }
	   98% {
	     transform: translate(4px, -7px) rotate(0.5deg); }
	   0%, 100% {
	     transform: translate(0, 0) rotate(0); } }

	 .shake-slow{
	   animation-name: shake-slow;
	   animation-duration: 5s;
	   animation-timing-function: ease-in-out;
	   animation-iteration-count: infinite;
	 }

	 .shake-slow-2{
		 animation-name: shake-slow;
		 animation-duration: 8s;
		 animation-timing-function: ease-in-out;
		 animation-iteration-count: infinite;
	 }

	 .shake-slow-3{
		animation-name: shake-slow;
		animation-duration: 10s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
	 }






	 @keyframes shake {
	   2% {
	     transform: translate(0.5px, 0.5px) rotate(-0.5deg); }
	   4% {
	     transform: translate(0.5px, 2.5px) rotate(1.5deg); }
	   6% {
	     transform: translate(-0.5px, 1.5px) rotate(0.5deg); }
	   8% {
	     transform: translate(2.5px, 1.5px) rotate(-0.5deg); }
	   10% {
	     transform: translate(-1.5px, 0.5px) rotate(1.5deg); }
	   12% {
	     transform: translate(2.5px, -1.5px) rotate(-0.5deg); }
	   14% {
	     transform: translate(0.5px, -0.5px) rotate(1.5deg); }
	   16% {
	     transform: translate(1.5px, 1.5px) rotate(-0.5deg); }
	   18% {
	     transform: translate(-0.5px, 0.5px) rotate(0.5deg); }
	   20% {
	     transform: translate(-0.5px, -0.5px) rotate(-0.5deg); }
	   22% {
	     transform: translate(-1.5px, -1.5px) rotate(-0.5deg); }
	   24% {
	     transform: translate(1.5px, -0.5px) rotate(1.5deg); }
	   26% {
	     transform: translate(1.5px, 1.5px) rotate(0.5deg); }
	   28% {
	     transform: translate(2.5px, 1.5px) rotate(0.5deg); }
	   30% {
	     transform: translate(2.5px, 0.5px) rotate(1.5deg); }
	   32% {
	     transform: translate(-0.5px, -0.5px) rotate(0.5deg); }
	   34% {
	     transform: translate(-1.5px, -0.5px) rotate(-0.5deg); }
	   36% {
	     transform: translate(-1.5px, 2.5px) rotate(0.5deg); }
	   38% {
	     transform: translate(2.5px, 2.5px) rotate(0.5deg); }
	   40% {
	     transform: translate(2.5px, 0.5px) rotate(0.5deg); }
	   42% {
	     transform: translate(1.5px, 2.5px) rotate(-0.5deg); }
	   44% {
	     transform: translate(0.5px, -1.5px) rotate(1.5deg); }
	   46% {
	     transform: translate(2.5px, 0.5px) rotate(1.5deg); }
	   48% {
	     transform: translate(-0.5px, -0.5px) rotate(1.5deg); }
	   50% {
	     transform: translate(2.5px, 1.5px) rotate(1.5deg); }
	   52% {
	     transform: translate(0.5px, 0.5px) rotate(0.5deg); }
	   54% {
	     transform: translate(-0.5px, -1.5px) rotate(-0.5deg); }
	   56% {
	     transform: translate(0.5px, 1.5px) rotate(0.5deg); }
	   58% {
	     transform: translate(2.5px, 0.5px) rotate(0.5deg); }
	   60% {
	     transform: translate(-1.5px, 0.5px) rotate(0.5deg); }
	   62% {
	     transform: translate(0.5px, 1.5px) rotate(0.5deg); }
	   64% {
	     transform: translate(-0.5px, 0.5px) rotate(-0.5deg); }
	   66% {
	     transform: translate(0.5px, 0.5px) rotate(0.5deg); }
	   68% {
	     transform: translate(-1.5px, 0.5px) rotate(1.5deg); }
	   70% {
	     transform: translate(-0.5px, 2.5px) rotate(1.5deg); }
	   72% {
	     transform: translate(-0.5px, 0.5px) rotate(0.5deg); }
	   74% {
	     transform: translate(-1.5px, 0.5px) rotate(0.5deg); }
	   76% {
	     transform: translate(-1.5px, 0.5px) rotate(0.5deg); }
	   78% {
	     transform: translate(-0.5px, 2.5px) rotate(1.5deg); }
	   80% {
	     transform: translate(-1.5px, 1.5px) rotate(-0.5deg); }
	   82% {
	     transform: translate(1.5px, 1.5px) rotate(-0.5deg); }
	   84% {
	     transform: translate(0.5px, -0.5px) rotate(1.5deg); }
	   86% {
	     transform: translate(0.5px, -1.5px) rotate(-0.5deg); }
	   88% {
	     transform: translate(1.5px, 1.5px) rotate(0.5deg); }
	   90% {
	     transform: translate(2.5px, 0.5px) rotate(-0.5deg); }
	   92% {
	     transform: translate(2.5px, -0.5px) rotate(-0.5deg); }
	   94% {
	     transform: translate(-1.5px, 1.5px) rotate(0.5deg); }
	   96% {
	     transform: translate(-1.5px, 0.5px) rotate(0.5deg); }
	   98% {
	     transform: translate(-1.5px, 0.5px) rotate(1.5deg); }
	   0%, 100% {
	     transform: translate(0, 0) rotate(0); } }


			 /*! * * * * * * * * * * * * * * * * * * * *\
			   CSShake :: shake-horizontal
			   v1.5.0
			   CSS classes to move your DOM
			   (c) 2015 @elrumordelaluz
			   http://elrumordelaluz.github.io/csshake/
			   Licensed under MIT
			 \* * * * * * * * * * * * * * * * * * * * */
			 .shake-horizontal {
			   display: inline-block;
			   transform-origin: center center; }

			 .shake-freeze,
			 .shake-constant.shake-constant--hover:hover,
			 .shake-trigger:hover .shake-constant.shake-constant--hover {
			   animation-play-state: paused; }

			 .shake-freeze:hover,
			 .shake-trigger:hover .shake-freeze, .shake-horizontal:hover,
			 .shake-trigger:hover .shake-horizontal {
			   animation-play-state: running; }

			 @keyframes shake-horizontal {
			   2% {
			     transform: translate(-5px, 0) rotate(0); }
			   4% {
			     transform: translate(-5px, 0) rotate(0); }
			   6% {
			     transform: translate(3px, 0) rotate(0); }
			   8% {
			     transform: translate(1px, 0) rotate(0); }
			   10% {
			     transform: translate(10px, 0) rotate(0); }
			   12% {
			     transform: translate(6px, 0) rotate(0); }
			   14% {
			     transform: translate(-2px, 0) rotate(0); }
			   16% {
			     transform: translate(5px, 0) rotate(0); }
			   18% {
			     transform: translate(7px, 0) rotate(0); }
			   20% {
			     transform: translate(3px, 0) rotate(0); }
			   22% {
			     transform: translate(-1px, 0) rotate(0); }
			   24% {
			     transform: translate(-7px, 0) rotate(0); }
			   26% {
			     transform: translate(1px, 0) rotate(0); }
			   28% {
			     transform: translate(6px, 0) rotate(0); }
			   30% {
			     transform: translate(10px, 0) rotate(0); }
			   32% {
			     transform: translate(6px, 0) rotate(0); }
			   34% {
			     transform: translate(5px, 0) rotate(0); }
			   36% {
			     transform: translate(-1px, 0) rotate(0); }
			   38% {
			     transform: translate(1px, 0) rotate(0); }
			   40% {
			     transform: translate(-9px, 0) rotate(0); }
			   42% {
			     transform: translate(5px, 0) rotate(0); }
			   44% {
			     transform: translate(-9px, 0) rotate(0); }
			   46% {
			     transform: translate(4px, 0) rotate(0); }
			   48% {
			     transform: translate(0px, 0) rotate(0); }
			   50% {
			     transform: translate(-7px, 0) rotate(0); }
			   52% {
			     transform: translate(8px, 0) rotate(0); }
			   54% {
			     transform: translate(7px, 0) rotate(0); }
			   56% {
			     transform: translate(9px, 0) rotate(0); }
			   58% {
			     transform: translate(10px, 0) rotate(0); }
			   60% {
			     transform: translate(-6px, 0) rotate(0); }
			   62% {
			     transform: translate(8px, 0) rotate(0); }
			   64% {
			     transform: translate(8px, 0) rotate(0); }
			   66% {
			     transform: translate(-9px, 0) rotate(0); }
			   68% {
			     transform: translate(-2px, 0) rotate(0); }
			   70% {
			     transform: translate(-8px, 0) rotate(0); }
			   72% {
			     transform: translate(4px, 0) rotate(0); }
			   74% {
			     transform: translate(4px, 0) rotate(0); }
			   76% {
			     transform: translate(-9px, 0) rotate(0); }
			   78% {
			     transform: translate(-9px, 0) rotate(0); }
			   80% {
			     transform: translate(7px, 0) rotate(0); }
			   82% {
			     transform: translate(-5px, 0) rotate(0); }
			   84% {
			     transform: translate(-5px, 0) rotate(0); }
			   86% {
			     transform: translate(4px, 0) rotate(0); }
			   88% {
			     transform: translate(9px, 0) rotate(0); }
			   90% {
			     transform: translate(-6px, 0) rotate(0); }
			   92% {
			     transform: translate(2px, 0) rotate(0); }
			   94% {
			     transform: translate(8px, 0) rotate(0); }
			   96% {
			     transform: translate(-1px, 0) rotate(0); }
			   98% {
			     transform: translate(-3px, 0) rotate(0); }
			   0%, 100% {
			     transform: translate(0, 0) rotate(0); } }

			 .shake-horizontal,
			 .shake-trigger:hover .shake-horizontal, .shake-horizontal.shake-freeze, .shake-horizontal.shake-constant {
			   animation-name: shake-horizontal;
			   animation-duration: 3s;
			   animation-timing-function: ease-in-out;
			   animation-iteration-count: infinite;
		 }

		 /*! * * * * * * * * * * * * * * * * * * * *\
	  CSShake :: shake-little
	  v1.5.0
	  CSS classes to move your DOM
	  (c) 2015 @elrumordelaluz
	  http://elrumordelaluz.github.io/csshake/
	  Licensed under MIT
	\* * * * * * * * * * * * * * * * * * * * */
	.shake-little {
	  display: inline-block;
	  transform-origin: center center; }

	.shake-freeze,
	.shake-constant.shake-constant--hover:hover,
	.shake-trigger:hover .shake-constant.shake-constant--hover {
	  animation-play-state: paused; }

	.shake-freeze:hover,
	.shake-trigger:hover .shake-freeze, .shake-little:hover,
	.shake-trigger:hover .shake-little {
	  animation-play-state: running; }

	@keyframes shake-little {
	  2% {
	    transform: translate(0px, 1px) rotate(0.5deg); }
	  4% {
	    transform: translate(0px, 1px) rotate(0.5deg); }
	  6% {
	    transform: translate(1px, 1px) rotate(0.5deg); }
	  8% {
	    transform: translate(0px, 0px) rotate(0.5deg); }
	  10% {
	    transform: translate(1px, 0px) rotate(0.5deg); }
	  12% {
	    transform: translate(1px, 1px) rotate(0.5deg); }
	  14% {
	    transform: translate(0px, 0px) rotate(0.5deg); }
	  16% {
	    transform: translate(1px, 0px) rotate(0.5deg); }
	  18% {
	    transform: translate(0px, 1px) rotate(0.5deg); }
	  20% {
	    transform: translate(0px, 1px) rotate(0.5deg); }
	  22% {
	    transform: translate(1px, 1px) rotate(0.5deg); }
	  24% {
	    transform: translate(0px, 1px) rotate(0.5deg); }
	  26% {
	    transform: translate(0px, 0px) rotate(0.5deg); }
	  28% {
	    transform: translate(1px, 0px) rotate(0.5deg); }
	  30% {
	    transform: translate(1px, 0px) rotate(0.5deg); }
	  32% {
	    transform: translate(0px, 1px) rotate(0.5deg); }
	  34% {
	    transform: translate(0px, 1px) rotate(0.5deg); }
	  36% {
	    transform: translate(0px, 0px) rotate(0.5deg); }
	  38% {
	    transform: translate(1px, 1px) rotate(0.5deg); }
	  40% {
	    transform: translate(1px, 0px) rotate(0.5deg); }
	  42% {
	    transform: translate(0px, 1px) rotate(0.5deg); }
	  44% {
	    transform: translate(0px, 1px) rotate(0.5deg); }
	  46% {
	    transform: translate(0px, 0px) rotate(0.5deg); }
	  48% {
	    transform: translate(0px, 1px) rotate(0.5deg); }
	  50% {
	    transform: translate(0px, 1px) rotate(0.5deg); }
	  52% {
	    transform: translate(1px, 1px) rotate(0.5deg); }
	  54% {
	    transform: translate(0px, 0px) rotate(0.5deg); }
	  56% {
	    transform: translate(0px, 0px) rotate(0.5deg); }
	  58% {
	    transform: translate(0px, 0px) rotate(0.5deg); }
	  60% {
	    transform: translate(0px, 1px) rotate(0.5deg); }
	  62% {
	    transform: translate(0px, 0px) rotate(0.5deg); }
	  64% {
	    transform: translate(1px, 0px) rotate(0.5deg); }
	  66% {
	    transform: translate(0px, 1px) rotate(0.5deg); }
	  68% {
	    transform: translate(0px, 0px) rotate(0.5deg); }
	  70% {
	    transform: translate(1px, 1px) rotate(0.5deg); }
	  72% {
	    transform: translate(1px, 0px) rotate(0.5deg); }
	  74% {
	    transform: translate(0px, 1px) rotate(0.5deg); }
	  76% {
	    transform: translate(0px, 1px) rotate(0.5deg); }
	  78% {
	    transform: translate(1px, 0px) rotate(0.5deg); }
	  80% {
	    transform: translate(1px, 0px) rotate(0.5deg); }
	  82% {
	    transform: translate(0px, 0px) rotate(0.5deg); }
	  84% {
	    transform: translate(1px, 0px) rotate(0.5deg); }
	  86% {
	    transform: translate(0px, 0px) rotate(0.5deg); }
	  88% {
	    transform: translate(0px, 0px) rotate(0.5deg); }
	  90% {
	    transform: translate(1px, 1px) rotate(0.5deg); }
	  92% {
	    transform: translate(1px, 1px) rotate(0.5deg); }
	  94% {
	    transform: translate(1px, 0px) rotate(0.5deg); }
	  96% {
	    transform: translate(1px, 0px) rotate(0.5deg); }
	  98% {
	    transform: translate(0px, 0px) rotate(0.5deg); }
	  0%, 100% {
	    transform: translate(0, 0) rotate(0); } }

	.shake-little{
	  animation-name: shake-little;
	  animation-duration: 4s;
	  animation-timing-function: ease-in-out;
	  animation-iteration-count: infinite; }
